.hero{
    height: 100vh;
    width: 100%;
    object-fit: contain;
}


.hero .content{
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero .content h1{
    font-size: 4rem;
    color: #fff;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.content .btn{
    position: relative;
    top: 10vh;
}


@media screen and (max-width: 1140px){
    .content .btn{
        position: relative;
        top: 0;
    }
}