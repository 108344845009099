.aboutus{
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.aboutus:before{
    content: '';
    background: url('../assets/images/image4.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.aboutus .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutus h1{
    font-size: 2.4rem;
}

.aboutus p{
    font-size: 1.4rem;
}

@media screen and (min-width: 640px){
    .aboutus h1{
        font-size: 2rem;
    }
}